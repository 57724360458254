<template>
    <painel :titulo="`Consultoria - ${consultoria.codAtendimento}`" icone="handshake" :refreshFunction="obterConsultoria" v-if="consultoria">
        <detalhe titulo="CPF do Cliente" :size="size">
            {{ $toCpfCnpj(consultoria.cpf) }}
        </detalhe>
        <detalhe titulo="Tipo do Cliente" :size="size">
            {{ consultoria.tipoEmpresa }}
        </detalhe>
        <detalhe titulo="Código do Cliente" :size="size">
            {{ consultoria.codigoEmpresa }}
        </detalhe>
        <detalhe titulo="Competência" :size="size">
            <span>{{ consultoria.competencia }}</span>
        </detalhe>
        <detalhe titulo="Início Atendimento" :size="size">
            {{ $dateFormat(consultoria.inicioAtendimento, 'DD/MM/YYYY HH:mm') }}
        </detalhe>
        <detalhe titulo="Final Atendimento" :size="size">
            {{ $dateFormat(consultoria.finalAtendimento, 'DD/MM/YYYY HH:mm') }}
        </detalhe>
        <detalhe titulo="Carga Horária" :size="size">
            <span>{{ decimalToHours(consultoria.cargaHoraria) }}</span>
        </detalhe>
        <detalhe titulo="Título" :size="size">
            <span>{{ consultoria.tituloAtendimento }}</span>
        </detalhe>
        <detalhe titulo="Tipo Atendimento" :size="size">
            <span>{{ consultoria.tipoAtendimento }}</span>
        </detalhe>
        <detalhe titulo="Instrumento" :size="size">
            <span>({{ consultoria.codInstrumento }}) {{ consultoria.instrumento }}</span>
        </detalhe>
        <detalhe titulo="Abordagem" :size="size">
            <span>({{ consultoria.abordagem }}) {{ consultoria.abordagemDescr }}</span>
        </detalhe>
        <detalhe titulo="Projeto" :size="size">
            <span>({{ consultoria.codProjeto }}) {{ consultoria.projeto }}</span>
        </detalhe>
        <detalhe titulo="Ação" :size="size">
            <span>({{ consultoria.codAcao }}) {{ consultoria.acao }}</span>
        </detalhe>
        <detalhe titulo="Tema" :size="size">
            <span>({{ consultoria.codTema }}) {{ consultoria.tema }}</span>
        </detalhe>
        <detalhe titulo="CPF do Gestor Responsável" :size="size">
            {{ $toCpfCnpj(consultoria.cpfResponsavel) }}
        </detalhe>
        <detalhe titulo="Descrição" :size="size">
            {{ consultoria.descricao }}
        </detalhe>
        <btn-voltar class="mt-4" :route="{ name: 'Utilidades_Consultorias' }"></btn-voltar>
        <span class="ml-2"><btn-atualizar :consultoria="consultoria" tipo="B"></btn-atualizar></span>
        <span class="ml-2"><btn-excluir :consultoria="consultoria" tipo="B"></btn-excluir></span>
    </painel>
</template>

<script>
import Service from './services';
import BtnAtualizar from './BtnAtualizar';
import BtnExcluir from './BtnExcluir';

export default {
    data() {
        return {
            size: '180',
            consultoria: null,
        };
    },

    components: {
        BtnAtualizar,
        BtnExcluir,
    },

    methods: {
        obterConsultoria() {
            this.$store.dispatch('addRequest');
            Service.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.consultoria = response.data;
                } else {
                    this.consultoria = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        decimalToHours(decimal) {
            const horas = Math.floor(decimal / 60);
            const minuto = decimal % 60;
            const textoHoras = horas.toString().padStart(3, '0');
            const textoMinutos = minuto.toString().padStart(2, '0');
            return `${textoHoras}:${textoMinutos}`;
        },
    },

    mounted() {
        this.obterConsultoria();
    },
};
</script>
